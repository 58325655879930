import {IGallerySantaProps, IGalleryStyleParams} from '../types/galleryTypes';
import {IPropsInjectedByViewerScript} from '../types/sliderGalleryTypes';

type ProvidedGlobalPropNames =
  | 'appLoadBI'
  | 'dimensions'
  | 'experiments'
  | 'getCategoryProducts'
  | 'handleProductItemClick'
  | 'isFirstPage'
  | 'isInteractive'
  | 'isLiveSiteMode'
  | 'isMobile'
  | 'isRTL'
  | 'mainCollectionId'
  | 'openQuickView'
  | 'productIdToProductPageUrlMap'
  | 'products'
  | 'showTitle'
  | 'textsMap'
  | 'totalProducts'
  | 'updateLayout';

export interface ISliderGlobalProps {
  globals?: Pick<IPropsInjectedByViewerScript & IGallerySantaProps, ProvidedGlobalPropNames> & {
    styleParams: IGalleryStyleParams;
  };
}

export const sliderGlobalPropsStrategy = (props: IPropsInjectedByViewerScript & IGallerySantaProps) => {
  const {
    experiments,
    getCategoryProducts,
    handleProductItemClick,
    isFirstPage,
    isInteractive,
    isLiveSiteMode,
    isMobile,
    isRTL,
    mainCollectionId,
    openQuickView,
    productIdToProductPageUrlMap,
    products,
    showTitle,
    style,
    textsMap,
    totalProducts,
    ...locals
  } = props;

  const {appLoadBI, dimensions, updateLayout} = props.host;
  return {
    globals: {
      appLoadBI,
      dimensions,
      experiments,
      getCategoryProducts,
      handleProductItemClick,
      isFirstPage,
      isInteractive,
      isLiveSiteMode,
      isMobile,
      isRTL,
      mainCollectionId,
      openQuickView,
      productIdToProductPageUrlMap,
      products,
      showTitle,
      styleParams: style.styleParams,
      textsMap,
      totalProducts,
      updateLayout,
    },
    locals,
  };
};
