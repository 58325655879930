const {sliderGlobalPropsStrategy} = require('./sliderGlobalStrategy');

const {
  withSentryErrorBoundary: sentry,
} = require('@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary');
const {SLIDER_GALLERY_DSN: DSN} = require('@wix/wixstores-client-core/dist/es/src/viewer-script/sentryConf');
const {getWrappedComponent: get} = require('../appConfig');
const {SliderGallery} = require('./components/SliderGallery/SliderGallery');

const sliderGallery = get({
  entryName: 'sliderGallery',
  Component: SliderGallery,
  globalPropsStrategy: sliderGlobalPropsStrategy,
});

module.exports = {
  default: {
    component: sentry(sliderGallery, {
      dsn: DSN,
      config: {environment: 'Native Component'},
    }),
  },
};
